import classname from "classnames/bind";
import styles from "./BaiViet.module.scss";
import { getAllBaiViet, getBaiVietByAlt } from "../../Action/BaiVietAction";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getAllNoiDungBaiViet } from "../../Action/NoiDungBaiViet";
import moment from "moment";
import { Link } from "react-router-dom";

const cx = classname.bind(styles);

function BaiViet() {
  const [baiViet, setBaiViet] = useState(null);
  const [noiDungbaiViet, setNoiDungBaiViet] = useState(null);
  const [allBaiViet, SetAllBaiViet] = useState(null);


  const urlParams = new URLSearchParams(window.location.search);
  const alt = urlParams.get("baiviet");


  useEffect(() => {
    getAllBaiViet()
        .then((DataBaiViet) => {
            if (DataBaiViet) {
                SetAllBaiViet(DataBaiViet);
            }
        });
    }, []);

  useEffect(() => {
    getBaiVietByAlt(alt).then((DataBaiViet) => {
      if (DataBaiViet) {
        setBaiViet(DataBaiViet);
      }
    });
  }, []);

  useEffect(() => {
    getAllNoiDungBaiViet(alt).then((DataBaiViet) => {
      if (DataBaiViet) {
        setNoiDungBaiViet(DataBaiViet);
      }
    });
  }, []);

  const renderContent = (content) => {
    if (typeof content === "string") {
      const lines = content.split("\n");
      return lines.map((line, index) => (
        <span>
        <span key={index} className={cx("line")}>{line}</span><br/>
        </span>
      ));
    }
  
    return null;
  };
  const formatUploadTime = (uploadTime) => {
    const now = moment();
    const uploadDate = moment(uploadTime);

    const diff = now.diff(uploadDate, 'days');
    
    if (diff === 0) {
      return 'Hôm nay';
    } else if (diff === 1) {
      return '1 ngày trước';
    } else if (diff >= 2 && diff <= 7) {
      return `${diff} ngày trước`;
    } else {
        return uploadDate.format('DD-MM-YYYY');
    }
  };

let reversedArray;
if(baiViet){
reversedArray = Array.from(allBaiViet).reverse();
}

return (
    <Container>
        <Row>
            <Col xl="8">
                <div className={cx("BaiViet")}>
                    <h1>{baiViet?.title}</h1>
                    <span>{new Date(baiViet?.createdAt).toLocaleDateString()}</span>
                    <br />

                    <img src={baiViet?.image} />
                    <div className={cx("content")}>{renderContent(baiViet?.content)}</div>
                </div>
                {noiDungbaiViet?.map((item, index) => baiViet?._id == item.baiViet_id && (
                    <div key={index} className={cx("NDBaiViet")}>
                        <h1>{item.title}</h1>
                        <div className={cx("content")}>{renderContent(item.content)}</div>
                        <img src={item?.image1} />
                        <img src={item?.image2} />
                    </div>
                ))}
            </Col>
            <Col>
                <div className={cx("BaiVietRight")}>
                    <h1>Bài viết Mới</h1>
                    {reversedArray && reversedArray?.slice(0, 4).map((item, index) => (
                        <a href={`/baiviet?baiviet=${item.alt}`}>
                        <div key={index} className={cx("item")}>
                            <img src={item.image} alt={item.title} className={cx("image")} />
                            <h5>{item.title}</h5>
                            <p>{formatUploadTime(item.createdAt)}</p>
                        </div>
                        </a>
                    ))}
                </div>
            </Col>
        </Row>
    </Container>
);
}

export default BaiViet;