import Construction from "../Show/Detail_Construction/Construction";
import Residential from "../Show/Construction/Construction";
import About from "../Show/About/About";
import Home from "../Show/Home/Home";
import People from "../Show/People/People";
import BaiViet from "../Show/BaiViet/BaiViet";

// Public routes
const publicRoutes = [
  { path: "/", component: Home },
  { path: "/about-us", component: About },
  { path: "/people", component: People },
  { path: "/project", component: Residential },
  { path: "/construction", component: Construction },
  { path: "/baiviet", component: BaiViet },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
