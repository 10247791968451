const initialState = [
  {
    avt: "0",
    name: "Phạm Thiên Chương",
    introduce: "",
    job: "KTS Công Trình",
  },
  {
    avt: "1",
    name: "Phạm Ngọc Hải",
    introduce: "",
    job: "KTS Cảnh Quan",
  },
  {
    avt: "2",
    name: "Nguyễn Ngọc Luân",
    introduce: "",
    job: "Kĩ Sư",
  },
  {
    avt: "3",
    name: "Phan Đức Tiến",
    introduce: "",
    job: "Kĩ Sư Xây Dựng",
  },
  {
    avt: "4",
    name: "Phạm Quang Khanh",
    introduce: "",
    job: "Kĩ sư điện nước",
  },
];

const OurTeam = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_MEMBER":
      return [...state, action.payload];
    default:
      return state;
  }
};

export default OurTeam;
