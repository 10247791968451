import axios from 'axios';
import { notification } from 'antd';

import { api } from '../api';

export const getAllBaiViet = async () => {
    try {
        const res = await axios.get(api('/api/baiviet/getAll'));
        return res.data;
    } catch (error) {
        return null;
    }
};
export const getBaiVietByAlt = async (alt) => {
    try {
        const res = await axios.get(api('/api/baiviet/getBaiVietByAlt'), { params: { alt } });
        return res.data;
    } catch (error) {
        console.log("Lỗi: " + error);
        return null;
    }
};
