import axios from 'axios';
import { api } from '../api';

export const getAllNoiDungBaiViet = async () => {
    try {
        const res = await axios.get(api('/api/noiDungBaiViet/getAll'));
        return res.data;
    } catch (error) {
        return null;
    }
};