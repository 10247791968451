
import classname from "classnames/bind";

import styles from "./Construction.module.scss";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";


//image
import NhaO3 from "../../image/Residential/3/1 (1).jpeg"
import NhaO4 from "../../image/Residential/4/1.jpeg"
import NhaO6 from "../../image/Residential/6/1.jpeg"
import NhaO7 from "../../image/Residential/7/1.jpeg"
import NhaO9 from "../../image/Residential/9/1.jpeg"
import NhaO12 from "../../image/Residential/12/1.jpeg"
import NhaO15 from "../../image/Residential/15/1.jpeg"
import NhaO19 from "../../image/Residential/19/1.jpeg"
import NhaO20 from "../../image/Residential/20/1.jpeg"
import NhaO21 from "../../image/Residential/21/1.jpeg"
import NhaO29 from "../../image/Residential/29/1.jpeg"
import NhaO30 from "../../image/Residential/30/TH_l_09.jpg"
import NhaO31 from "../../image/Residential/31/01-705.jpg"
import NhaO32 from "../../image/Residential/32/02-7114.jpg"
import NhaO33 from "../../image/Residential/33/TH_l_04.jpg"
import NhaO34 from "../../image/Residential/34/ABreathing House_pic_20_oki.jpg"
import NhaO35 from "../../image/Residential/35/pic01_Exterior_OKI.jpg"
import NhaO36 from "../../image/Residential/36/00_Hero Image_1627636426_1.jpg"
import { Link, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

const cx = classname.bind(styles);

function Residential() {
  const ResidentialArray = useSelector(state => state.Construction);
  const ResidentialImages = [NhaO3,NhaO4,NhaO6,NhaO7,NhaO9,NhaO12,NhaO15,NhaO19,NhaO20,NhaO21,NhaO29,NhaO30,NhaO31,NhaO32,NhaO33,NhaO34,NhaO35,NhaO36];
  const location = useLocation();
  const Construction = location.pathname.split('/').filter(Boolean)[0];

  useEffect(() => {
    document.title = 'Kiến trúc Ohyo';
  }, []);
return (
  <div>
    <Row className={cx("list_congtrinh")}>
      {ResidentialArray.map((ct, index) => (

        <React.Fragment key={index}>
          
          <Col xl={3} xs={9} className={cx("congtrinh")}>
            <Link to={`/construction?slug=${ct.slug}`} className={cx("link")}>
                <img src={ResidentialImages[ct.image]} alt={ct.name} />
                <div className={cx("overlay")}></div>
              <div className={cx("ND")}>
                <h6>{ct.name.toUpperCase()}</h6>
              </div>
            </Link>
          </Col>
        </React.Fragment>
      ))}
    </Row>
  </div>
);
}

export default Residential;