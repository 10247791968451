import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classname from "classnames/bind";
import 'swiper/swiper-bundle.css';
import 'swiper/modules';
import styles from "./Construction.module.scss";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Col, Modal, Row } from 'react-bootstrap';
import { Navigation, Pagination, History, Scrollbar, A11y, Keyboard, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';
const cx = classname.bind(styles);



function Construction() {
  const location = useLocation();
  const [hover, setHover] = useState(false);
  const query = new URLSearchParams(location.search);
  const slug = query.get('slug'); 

  const residential = useSelector(state => state.Construction);
  const ct = residential.find(item => item.slug === slug); 

  useEffect(() => {
    if (ct) {
        document.title = ct.name;
    }
  }, [ct]);

  const importAll = requireContext => requireContext.keys().map(requireContext);

  let images;

  switch (slug) {
    case 'long-an-houses':
      images = importAll(require.context(`../../image/Residential/3`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'bac-lieu-villa':
      images = importAll(require.context(`../../image/Residential/4`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'nfk-houses':
      images = importAll(require.context(`../../image/Residential/6`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'lk-houses':
      images = importAll(require.context(`../../image/Residential/7`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'long-khanh-houses':
      images = importAll(require.context(`../../image/Residential/9`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'lam-dong-houses':
      images = importAll(require.context(`../../image/Residential/12`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'ldh-houses':
      images = importAll(require.context(`../../image/Residential/15`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'ca-mau-houses':
      images = importAll(require.context(`../../image/Residential/19`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'can-tho-villas':
      images = importAll(require.context(`../../image/Residential/20`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'people-villas':
      images = importAll(require.context(`../../image/Residential/21`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'vivi-villas':
      images = importAll(require.context(`../../image/Residential/29`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'ptd-house':
      images = importAll(require.context(`../../image/Residential/30`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'dn-house':
      images = importAll(require.context(`../../image/Residential/31`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'termitary-house':
      images = importAll(require.context(`../../image/Residential/32`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'thang-house':
      images = importAll(require.context(`../../image/Residential/33`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'hcm-house':
      images = importAll(require.context(`../../image/Residential/34`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'bb-house':
      images = importAll(require.context(`../../image/Residential/35`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'bin-house':
      images = importAll(require.context(`../../image/Residential/36`, false, /\.(png|jpe?g|svg)$/));
      break;
    default:
      break;
  }

  const handleHover = () => {
    setHover(!hover);
  };


const imageGalleryItems = images.map((image, index) => ({
  original: image,
  thumbnail: image,
  sizes: '100%',
  alt: ct.name
}));

  return ( 
    <div>
      {ct && (
        <div className={cx("container")}>
        <ImageGallery items={imageGalleryItems} className={cx("custom-image-gallery")} showThumbnails={true}/>
          <div className={cx("detail")} onMouseEnter={handleHover} onMouseLeave={handleHover}>
           <Row style={{ display: 'flex' }}>
              <Col className={cx("detail_list")} >
                <ul>
                  {Object.keys(ct.TTChiTiet).map(key => (
                    <li key={key}>{`${ct.TTChiTiet[key]}`}</li>
                  ))}
                </ul>
              </Col>
              <Col xl="5" className={cx("detail_info")} >
                <h3>{ct.name}</h3>
                <p>{ct.type === 'tkNhaO' ? "Thiết kế nhà ở" : "Thiết kế cảnh quan"}</p>
              </Col>
            </Row>
          </div>
          
        </div>
      )}
    </div>
  );
}

export default Construction;