const initialState = [
  {
    id: 3,
    type: "tkNhaO",
    image: 0,
    name: "LONG AN HOUSE'S",
    slug: 'long-an-houses',
    TTChiTiet: {
      Điaiem: "Địa điểm: Tân An, Long An, Việt Nam",
      DTKD: "Diện tích khu đất: 120 m2 (6X20m)",
      QuyMo: "Qui mô công trình: 2 tầng",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1: Sân trước, Không gian làm việc, 01 Phòng khách – bếp – ăn, Kệ sách trẻ em, khu nghỉ ngơi, sân sau, 01 wc",
      Tang2:
        "Tầng 2: 01 Phòng ngủ Master, 01 Phòng ngủ trẻ em, 01 WC, thông tầng",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 4,
    type: "tkNhaO",
    image: 1,
    name: "BẠC LIÊU Villa",
    slug: 'bac-lieu-villa',
    TTChiTiet: {
      Điaiem: "Địa điểm: Vĩnh Trạch, Bạc Liêu, Việt Nam",
      DTKD: "Diện tích khu đất: 100 m2 (20x5m)",
      QuyMo: "Qui mô công trình: 3 tầng",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1: "Tầng 1: 01 Phòng khách – bếp – ăn –hồ bơi- phòng tắm hơi",
      Tang2:
        "Tầng 3: 01 Phòng thờ, 01 Phòng ngủ & 01 WC, thông tầng, giặt sấy, sân vườn",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 6,
    type: "tkNhaO",
    image: 2,
    name: "NFK HOUSE'S",
    slug: 'nfk-houses',
    TTChiTiet: {
      Điaiem: "Địa điểm: Tân Bình Thuận, Tiền Giang, Việt Nam",
      DTKD: "Diện tích khu đất: 115 m2 (5X23m)",
      QuyMo: "Qui mô công trình: 2 tầng",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1: Sân trước, 01 Phòng khách,01bếp-ăn, 01 Sân trong,01 phòng ngủ, 01 wc, 01 phòng giặt",
      Tang2:
        "Tầng 2: Khu đọc sách,01 Sinh hoạt chung, Khu thờ, 01 Phòng ngủ , 01 WC, thông tầng",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 7,
    type: "tkNhaO",
    image: 3,
    name: "LK HOUSE'S",
    slug: 'lk-houses',
    TTChiTiet: {
      Điaiem: "Địa điểm: Long Khánh ,Đồng Nai, Việt Nam",
      DTKD: "Diện tích khu đất: 115 m2 (5X23m)",
      QuyMo: "Qui mô công trình: 2 tầng",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1: Sân trước, 01 Phòng khách,01 bếp-ăn, 01 Sân trong,01 phòng ngủ, 01 wc, 01 phòng giặt",
      Tang2:
        "Tầng 2: Khu đọc sách,01 Sinh hoạt chung, Khu thờ, 01 Phòng ngủ , 01 WC, thông tầng",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 9,
    type: "tkNhaO",
    image: 4,
    name: "Long Khánh HOUSE'S",
    slug: 'long-khanh-houses',
    TTChiTiet: {
      Điaiem: "Địa điểm: Long Khánh ,Đồng Nai, Việt Nam",
      DTKD: "Diện tích khu đất: 74.75 m2 (6.5X11.5m)",
      QuyMo: "Qui mô công trình: 4 tầng",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1: Ban công, hành lang, cầu thang , 02 phòng ngủ, 01 phòng vệ sinh, thông tầng",
      Tang2: "Tầng 2: Hồ cá, 01 phòng khách, Bếp, Gara, 01 phòng vệ sinh",
      Tang3:
        "Tầng 3: Ban công, Hành lang, Cầu thang, 02 Phòng ngủ, 01 WC, Phòng uống trà ,Thông tầng",
      Tang4:
        "Tầng 4: Ban công, Hành lang, Phòng tập thể dục, Phòng thờ, Phòng giặt, Thông tầng, Kho",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 12,
    type: "tkNhaO",
    image: 5,
    name: "Lâm đồng HOUSE'S",
    slug: 'lam-dong-houses',
    TTChiTiet: {
      Điaiem: "Địa điểm: Bảo Lộc, Lâm Đồng, Việt Nam",
      DTKD: "Diện tích khu đất: 207 m2 (9X23m)",
      QuyMo: "Qui mô công trình: 2 tầng",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1: Gara + sân trước, phòng khách,01 phòng vệ sinh, thông tầng, bếp",
      Tang2: "Tầng 2: Phòng ngủ Master, 01 phong ngủ, 01 phòng làm việc",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 15,
    type: "tkNhaO",
    image: 6,
    name: "LDH HOUSE'S",
    slug: 'ldh-houses',
    TTChiTiet: {
      Điaiem: "Địa điểm: Bảo Lộc, Lâm Đồng, Việt Nam",
      DTKD: "Diện tích khu đất: 207 m2 (9X23m)",
      QuyMo: "Qui mô công trình: 2 tầng",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1: Gara + sân trước, phòng khách,01 phòng vệ sinh, thông tầng, bếp",
      Tang2: "Tầng 2: Phòng ngủ Master, 01 phong ngủ, 01 phòng làm việc",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 19,
    type: "tkNhaO",
    image: 7,
    name: "Cà Mau HOUSE'S",
    slug: 'ca-mau-houses',
    TTChiTiet: {
      Điaiem: "Địa điểm: Tân Thành, Cà Mau, Việt Nam",
      DTKD: "Diện tích khu đất: 8x12",
      QuyMo: "Qui mô công trình: 3 tầng (1 trệt 2 lầu)",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1: Gara + sân trước, phòng khách,01 phòng vệ sinh, bếp, 01 phòng ngủ, Kho, Phòng giặt ủi",
      Tang2: "Tầng 2: Phòng ngủ Master, 01 phong ngủ, Sảnh chung ",
      Tang3:
        "Tầng 3:  01 Phòng ngủ con, 01 phòng ngủ khách, Sảnh chung, ban công, thang lên mái",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 20,
    type: "tkNhaO",
    image: 8,
    name: "Cần Thơ Villa's",
    slug: 'can-tho-villas',
    TTChiTiet: {
      Điaiem: "Địa điểm: Vị Thanh, TP.Cần Thơ, Việt Nam",
      DTKD: "Diện tích khu đất: 8x15",
      QuyMo: "Qui mô công trình: 2 tầng (1 trệt 1 lầu)",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1: Gara + sân trước, Sảnh, Phòng tủ đồ, phòng khách,01 phòng vệ sinh, bếp,Kho thực phẩm, Phòng ủi đồ, Hiên sau, Phòng xông hơi",
      Tang2:
        "Tầng 2: Sảnh, Phòng giặt ủi, hiên, 02 Phòng ngủ thường, 01 Phòng ngủ master, Phòng đồ",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 21,
    type: "tkNhaO",
    image: 9,
    name: "People Villa's",
    slug: 'people-villas',
    TTChiTiet: {
      Điaiem: "Địa điểm:Vĩnh Thạnh, TP.Cần Thơ, Việt Nam",
      DTKD: "Diện tích khu đất: 9x19",
      QuyMo: "Qui mô công trình: 2 tầng (1 trệt 1 lầu)",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1: "Tầng 1: Gara + sân trước,  Phòng khách, Bếp, 01 Wc",
      Tang2:
        "Tầng 2: Sảnh + Sinh hoạt chung, 02 Phòng ngủ thường, 01 Phòng ngủ master, Phòng đồ",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 29,
    type: "tkNhaO",
    image: 10,
    name: "Vivi Villa's",
    slug: 'vivi-villas',
    TTChiTiet: {
      Điaiem: "Địa điểm: Quy Nhơn, Bình Định, Việt Nam",
      DTKD: "Diện tích khu đất: 12x18",
      QuyMo: "Qui mô công trình: 23 tầng (1 trệt 2 lầu)",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1: Gara + sân trước,  Phòng khách, Bếp, 02 Wc, Phòng ngủ người già, Sân sau",
      Tang2:
        "Tầng 2:  01 Phòng ngủ thường, 01 Phòng ngủ master, Phòng đọc sách",
      Tang3: "Tầng 3:  01 Phòng ngủ thường, 01 WC, 01 Phòng ăn lớn",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 30,
    type: "tkNhaO",
    image: 11,
    name: "PTD HOUSE",
    slug: 'ptd-house',
    TTChiTiet: {
      Điaiem: "Địa điểm: Tân phú TP. HCM, Việt Nam",
      DTKD: "Diện tích khu đất: 9x10",
      QuyMo: "Qui mô công trình:  04 Tầng (1 trệt, 1 lửng, 2 lầu)",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      TangTret:
        "Tầng trệt là không gian tiếp khách, bếp và bàn ăn lớn. Tầng này kết nối linh hoạt với khe sáng, tầng lửng và không gian ngoài trời để tạo cảm giác rộng rãi và thoáng đãng.",
      TangLung:
        "Tầng lửng là không gian sinh hoạt gia đình với điểm nhấn là một góc đàn piano và tấm phản gỗ để đọc sách và thư giãn.",
      Tang1:
        " Lầu 1 chứa phòng ngủ chính với phòng tắm và phòng quần áo lớn, cùng một góc làm việc nhỏ.",
      Tang2:
        "Lầu 2 bao gồm phòng thờ và hai phòng ngủ cho trẻ nhỏ. Đồ nội thất trong nhà chủ yếu được làm từ gỗ trò chỉ xẻ và sấy khô, tạo nên không gian ấm cúng và gần gũi. Vật liệu xây dựng như bê tông trần, gạch nung và gỗ được sử dụng hài hòa để tạo ra một không gian thô mộc nhưng ấm áp và thân thiện. ",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 31,
    type: "tkNhaO",
    image: 12,
    name: "DN HOUSE",
    slug: 'dn-house',
    TTChiTiet: {
      Điaiem: "Địa điểm:  Long Thành, Đồng Nai Việt Nam",
      DTKD: "Diện tích khu đất: 6.5x10",
      QuyMo: "Qui mô công trình:  02 Tầng (1 trệt, 2 lầu)",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1: "Tầng 1: Sân trước, bar, Khu uống cafe, Kho, WC",
      Tang2: "Tầng 2:  02 Phòng ngủ, WC, Bếp, Phòng Ăn, Thông tầng",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 32,
    type: "tkNhaO",
    image: 13,
    name: "Termitary HOUSE",
    slug: 'termitary-house',
    TTChiTiet: {
      Điaiem: "Địa điểm:  Đà Nẵng, Việt Nam",
      DTKD: "Diện tích khu đất: 6x15",
      QuyMo: "Qui mô công trình:  02 Tầng (1 trệt, 2 lầu)",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1:
        "Tầng 1:  Sân trước, khách, 01 phòng ngủ, bếp, 01 Wc, Pantry, Gara nhỏ",
      Tang2: "Tầng 2:  01 Phòng ngủ, WC, Thông tầng, Thư viện, Sinh hoạt chung",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 33,
    type: "tkNhaO",
    image: 14,
    name: "THANG HOUSE",
    slug: 'thang-house',
    TTChiTiet: {
      Điaiem: "Địa điểm:  Đà Nẵng, Việt Nam",
      DTKD: "Diện tích khu đất: 250m2",
      QuyMo: "Qui mô công trình:  03 Tầng (1 trệt, 2 lầu)",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1: "Tầng 1:  Sân trước, 02 phòng ngủ, bếp, 02 Wc, Khách",
      Tang2: "Tầng 2: Bếp, Khách,thông tầng, sinh hoạt chung",
      Tang3: "Tầng 3: Phòng ngủ master, 01 WC, Sân thư giãn",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 34,
    type: "tkNhaO",
    image: 15,
    name: "HCM HOUSE",
    slug: 'hcm-house',
    TTChiTiet: {
      Điaiem: "Địa điểm: Tp. Hồ Chí Minh, Việt Nam",
      DTKD: "Diện tích khu đất: 70m2",
      QuyMo: "Qui mô công trình:  04 Tầng (1 trệt, 3 lầu)",
      KhoangCach: "ㅤ",
      TDTXD: "Số lượng phòng chức năng: ",
      Tang1: "Tầng 1:  Phòng khách, bếp, WC, Phòng Ăn",
      Tang2: "Tầng 2: Phong ngủ master, ban công, sảnh chung, WC",
      Tang3: "Tầng 3: Phòng ngủ trẻ em, ban công, phòng tắm",
      Tang3: "Tầng 4: Phòng thờ,phòng giặt ủi, sinh hoạt, Hiên",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 35,
    type: "tkNhaO",
    image: 16,
    name: "BB HOUSE",
    slug: 'bb-house',
    TTChiTiet: {
      Điaiem: "Địa điểm: Tp. Hồ Chí Minh, Việt Nam",
      DTKD: "Diện tích khu đất: 60m2",
      QuyMo: "Qui mô công trình:  05 Tầng (1 trệt, 4 lầu)",
      KhoangCach: "ㅤ",
      TDTXD:
        "BB House là một dự án nhà ở nhỏ nằm trong hẻm hẹp ở thành phố Hồ Chí Minh, Việt Nam. Với mục tiêu phát triển ý tưởng 'Nhà cho Cây', dự án này tạo ra không gian sống thoải mái và xanh mát trong một không gian hẹp. Cây xanh được tích hợp mạnh mẽ trong thiết kế, từ các bồn cây trước nhà đến cây leo che bớt ánh nắng mặt trời. Việc sử dụng cây xanh không chỉ tạo cảm giác gần gũi với thiên nhiên mà còn giảm việc sử dụng máy điều hòa. Thiết kế thống nhất với việc sử dụng tre và bê tông giúp tạo ra không gian mở và thoáng đãng, kết nối con người với nhau và với thiên nhiên trong một không gian chật hẹp của thành phố đô thị.",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
  {
    id: 36,
    type: "tkNhaO",
    image: 17,
    name: "BIN HOUSE",
    slug: 'bin-house',
    TTChiTiet: {
      Điaiem: "Địa điểm: Tp. Hồ Chí Minh, Việt Nam",
      DTKD: "Diện tích khu đất: 320m2",
      QuyMo: "Qui mô công trình:  03 Tầng (1 trệt, 2 lầu)",
      KhoangCach: "ㅤ",
      TDTXD:
        "Ngôi nhà là một phần của dự án nhà cho cây nhằm xanh hóa đô thị trong bối cảnh diện tích cây xanh ở các thành phố lớn ở Việt Nam đang giảm đi. Thiết kế tạo sự kết nối giữa các thế hệ trong gia đình bằng cách đặt vườn cây xen kẽ giữa các không gian và sử dụng cửa kính trượt để tạo cảm giác rộng rãi và tăng tương tác giữa các thành viên. Các không gian phòng chức năng liên tục và đặt ở hướng tây để hạn chế bức xạ nhiệt. Vườn trên mái được sử dụng để trồng cây bóng mát và rau, phù hợp với phong cách sống và nhu cầu của người Việt. Sử dụng vật liệu bền vững và giải pháp vi khí hậu tối ưu giúp giảm chi phí vận hành và bảo trì, đồng thời tạo kết nối giữa con người và thiên nhiên thông qua kiến trúc.",
      KhoangCach2: "ㅤ",
      TGTH: "Thời gian thực hiện thiết kế: 2,0 tháng",
    },
  },
];

const Construction = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_Construction":
      return [...state, action.payload];
    default:
      return state;
  }
};

export default Construction;