
import { Col, Container, Row } from "react-bootstrap";

import classname from "classnames/bind";
import styles from "./About.module.scss";
import { useEffect } from "react";

const cx = classname.bind(styles);
function About() {
    useEffect(() => {
        document.title = 'Kiến trúc Ohyo';
      }, []);
    return ( 
        <div>
            <div className={cx("Title")}>
            </div>
            <Container>
                <Row>
                    <Col className={cx("content")}>
                        <div className={cx("tieude")}>Chào mừng đến với Kiến Trúc Ohyo!</div>
                        <hr/>
                        <p>Kiến Trúc Ohyo tự hào là một đội ngũ chuyên về thiết kế, thi công kiến trúc, nội thất và cảnh quan, ra đời từ năm 2021. 
                            <br/> Tại mỗi dự án, chúng tôi không ngừng đặt mình vào vai trò của người sáng tạo, hướng đến những ý tưởng thiết kế sáng tạo và độc đáo. Tuy nhiên, chúng tôi cũng nhận thức rằng công năng, chi tiết và sự tinh tế là những yếu tố không thể thiếu. Những điều nhỏ nhặt này chính là nền tảng cho sự thành công của mỗi công trình. 
                            <br/> Với đôi mắt tinh tế và đôi bàn tay khéo léo, chúng tôi tập trung vào từng chi tiết nhỏ để tạo ra những sản phẩm kiến trúc vượt thời gian và độc đáo. Ở Kiến Trúc Ohyo, chúng tôi luôn đặt chất lượng lên hàng đầu, không chỉ trong quy trình thiết kế mà còn trong quy trình thi công. Mỗi dự án được tiếp cận một cách riêng biệt, đảm bảo rằng sản phẩm cuối cùng không chỉ hoàn thiện mà còn giữ được tính độc đáo và cá nhân.
                            <br/> Chúng tôi luôn khao khát mang lại sự trau dồi và sự cống hiến để biến ước mơ của bạn thành hiện thực. Hãy để Kiến Trúc Ohyo cùng bạn tạo nên những không gian sống tuyệt vời và độc đáo nhất, nơi mà bạn có thể tận hưởng cuộc sống mỗi ngày một cách đắm chìm và trọn vẹn.
                            <br/> Trân trọng.
                        </p>
                        <div className={cx("tieude")}>O U RㅤA W A R D S</div>
                        <hr/>
                        <p>TƯ VẤN THIẾT KẾ, NỘI THẤT, NGOẠI CẢNH CHO CÁC CÔNG TRINH DÂN DỤNG VÀ THƯƠNG MẠI- DỊCH VỤ</p>
                    </Col>
                </Row>
            </Container>
        </div>
     );
}

export default About;