import { configureStore } from "@reduxjs/toolkit";
import OurTeam from "../Reducers/OurTeam";
import Construction from "../Reducers/Construction";

const store = configureStore({
  reducer: {
    team: OurTeam,
    Construction: Construction,
  },
});

export default store;
