import classname from "classnames/bind";

import styles from "./Home.module.scss";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";




// import image
import image2 from "../../image/Home/Home2.jpg";
import image3 from "../../image/Home/Home3.jpg";
import image4 from "../../image/Home/Home4.jpg";
import { useEffect, useState } from "react";
import moment from "moment";
import { getAllBaiViet } from "../../Action/BaiVietAction";
import { Link } from "react-router-dom";
import { faPlus, faChevronDown, faAnglesDown } from "@fortawesome/free-solid-svg-icons";

const cx = classname.bind(styles);

function Home() {
  
  const [showScrollIcon, setShowScrollIcon] = useState(true);
  const [baiViet, setBaiViet] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= 100 ) {
        setShowScrollIcon(true);
      } else {
        setShowScrollIcon(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  useEffect(() => {
      getAllBaiViet()
          .then((DataBaiViet) => {
              if (DataBaiViet) {
                  setBaiViet(DataBaiViet);
              }
          });
      }, []);

      const formatUploadTime = (uploadTime) => {
          const now = moment();
          const uploadDate = moment(uploadTime);
      
          const diff = now.diff(uploadDate, 'days');
          
          if (diff === 0) {
            return 'Hôm nay';
          } else if (diff === 1) {
            return '1 ngày trước';
          } else if (diff >= 2 && diff <= 7) {
            return `${diff} ngày trước`;
          } else {
              return uploadDate.format('DD-MM-YYYY');
          }
        };

      let reversedArray;
  if(baiViet){
      reversedArray = Array.from(baiViet).reverse();
      }

  useEffect(() => {
    document.title = 'Kiến trúc Ohyo';
  }, []);
  return (
    <div>
      <div className={cx("gioiThieu")}>
        <h5>X I NㅤC H À O</h5>
        <p>
          XIN CHÀO
          Kiến Trúc Ohyo, một đội ngũ chuyên về kiến trúc, nội thất và cảnh quan, đã ra đời từ năm 2021 với niềm đam mê không ngừng trong việc tạo ra những không gian sống độc đáo và tinh tế.
        </p>
        <p>
          Chúng tôi luôn đặt mình vào vị trí của khách hàng để hiểu rõ nhất nhu cầu và mong muốn của họ. Mỗi dự án đều được tiếp cận với tinh thần sáng tạo và sự cẩn trọng đến từng chi tiết nhỏ. Chúng tôi tin rằng không gian sống không chỉ đẹp mắt mà còn phải thực sự hữu ích và thoải mái.
        </p>
        <p>
          Với tư duy sáng tạo và kỹ năng chuyên môn vững vàng, chúng tôi cam kết mang đến cho khách hàng những giải pháp thiết kế độc đáo và đầy tính thực tiễn. Tại Kiến Trúc Ohyo, chúng tôi không ngừng trau dồi và cống hiến mình để biến ước mơ của khách hàng thành hiện thực, một không gian sống hoàn hảo và độc đáo, nơi mà họ có thể tận hưởng cuộc sống mỗi ngày một cách trọn vẹn. Hãy để chúng tôi cùng bạn thực hiện những điều kỳ diệu
        </p>
      </div>
      <Carousel
        touch={true}
        prevIcon=""
        nextIcon=""
        interval={3500}
        className={cx("carousel")}
      >
        <Carousel.Item>
          <img className="d-block w-100" src={image2} />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={image3} />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={image4} />
        </Carousel.Item>
      </Carousel>
          <div>

            {showScrollIcon && (
              <div className={cx("scrollIcon")}>
                <FontAwesomeIcon icon={faAnglesDown} />
                <p>Kéo xuống để xem thêm bài viết</p>
              </div>
            )}
          </div>
      <Container className={cx("BaiViet")}>
                <Row>
                    {reversedArray && reversedArray.map((item, index) => (
                        <Col xl='3' xm='5' key={index}>
                        <Link to={`/baiviet?baiviet=${item.alt}`}>
                            <div className={cx("item")}>
                                <img src={item.image} alt={item.title} className={cx("image")} />
                                <h5>{item.title}</h5>
                                {/* <p>{item.content}</p> */}
                                <p>{formatUploadTime(item.createdAt)}</p>
                            </div>
                        </Link>
                        </Col>
                    ))}

                </Row>
            </Container>
    </div>
  );
}

export default Home;
