import { Col, Container, Row } from "react-bootstrap";
import classname from "classnames/bind";
import styles from "./People.module.scss";
import logo from "../../image/Ohyo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";



import HinhChuong from "../../image/People/HÌNH CHUONG.png";
import HinhPin from "../../image/People/HINH PIN.png";
import HinhHai from "../../image/People/HINH HAI.png";
import HinhTien from "../../image/People/HinhPhanDucTien.jpg";
import HinhKhanh from "../../image/People/HinhPhamQuangKhanh.jpg";
import { useEffect } from "react";

const cx = classname.bind(styles);


function People() {
    const peopleImages = [HinhChuong, HinhHai, HinhPin, HinhTien, HinhKhanh];
    const members = useSelector(state => state.team);

    useEffect(() => {
      document.title = 'Kiến trúc Ohyo';
    }, []);
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://www.tiktok.com/embed.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, []);
  return (
    <div>
      <div className={cx("Title")}>
      </div>

      <Row className={cx("List_people")}>
        {members.map((member, index) => (
          <Col key={index} xl={2} className={cx("people")}>
            <img src={peopleImages[member.avt]} alt={member.name} />
            <div className={cx("overlay")}>{member.introduce}</div>
            <div className={cx("ND")}>
              <h6>{member.name}</h6>
              <p>{member.job}</p>
            </div>
          </Col>
        ))}
      </Row>

      <Container>
        <Row className={cx("info")}>
          <Col className={cx("left")}>
            <img className={cx("logo")} src={logo} alt="Logo" />
          </Col>
          <Col className={cx("center")}>
            <h4>Liên Hệ</h4>
            <hr />
            <p>
              <FontAwesomeIcon icon={faLocationDot} /> Quận 7, Tp.HCM
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> 0333172956
            </p>
          </Col>
          <Col className={cx("right")}>
            <h4>Trang</h4>
            <hr />
            <blockquote
              className={cx("tiktok-embed", "tiktok")}
              cite="https://www.tiktok.com/@kientrucohyo"
              data-unique-id="kientrucohyo"
              data-embed-type="creator"
            >
              <section>
                <a target="_blank" href="https://www.tiktok.com/@kientrucohyo?refer=creator_embed">
                  @kientrucohyo
                </a>
              </section>
            </blockquote>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default People;