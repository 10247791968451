
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useEffect, useState } from "react";

import facebook from "../../image/square-facebook.svg";
import instagram from "../../image/instagram.svg";
import tiktok from "../../image/tiktok.svg";
import logo from "../../image/Ohyo.jpg";
import { Link } from "react-router-dom";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import classname from "classnames/bind";

import styles from "./Header.module.scss";
const cx = classname.bind(styles);

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx("header")}>

      <Row>
          <Col xl="1" xs="3" className={cx("logo")}>
            <Link to="/">
              <img src={logo} />
            </Link>
          </Col>
        <Col xl="1" xs="3" className={cx("Projects", "option")}>
          <Link to='/'><p>Home</p></Link>
        </Col>
        <Col xl="1" xs="3" className={cx("Projects", "option")}>
          <Link to='/project'><p>Projects</p></Link>
        </Col>

        <Col xl="1" xs="3" className={cx("about", "option")}>
          <Link to='/about-us'><p>about</p></Link>
        </Col>
        <Col xl="1" xs="3" className={cx("People", "option")}>
          <Link to='/people'><p>People</p></Link>
        </Col>
        <Col xl="4" className={cx("space")}></Col>
        <Col xl="0" sm='2' xs="0" className={cx("spaced")}></Col>
        <Col xl="3" xs="10" className={cx("icon")}>
          <a
            href="https://www.facebook.com/profile.php?id=61558446836479"
            target="_blank"
          >
            <img src={facebook} />
          </a>
          <a href="https://www.instagram.com/kientrucohyo/" target="_blank">
            <img src={instagram} />
          </a>
          <a
            href="https://www.tiktok.com/@kientrucohyo?_t=8lbYAMKgz1l&_r=1"
            target="_blank"
          >
            <img src={tiktok} />
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default Header;
